import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import {
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  FROM,
  TO,
  TODAY,
  YESTERDAY,
  DAYSELECT_OPTIONS,
  PAGE_SIZE,
} from 'lib/constants';
import { buildDate, getTimeOptions } from 'lib/helpers';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Searchbar from './Searchbar/Searchbar';
import { useTranslation } from 'hooks';
import Select from './Select/Select';
import { Option } from './Select/types';
import Spinner from './Spinner';
import StockChangeLog from './StockChangeLog';
import NumberInput, { type NumberInputProps } from './Input/NumberInput';
import { useGetHubStockChangeLogsQuery } from 'graphql/__generated__/hooks';
import ReasonSelector from './Select/ReasonSelector';
import { HubStockChangeLogs, V1InventoryChangeReason } from 'graphql/__generated__/schemas';

const headers = ['reason', 'sku', 'productname', 'orderId', 'userId', 'date', 'stocklevel'];

type filterType = {
  queryString: string;
  sku: string;
  selectedReasons: string[];
  selectedDateRange: Array<Date>;
  selectedDay: Option;
  startTime: Option;
  endTime: Option;
  previousXDays: number | undefined;
  changedQuantityGreaterThanX: number | undefined;
  offset: string;
};

const DEFAULT_FILTER = {
  queryString: '',
  sku: '',
  selectedReasons: [],
  offset: '0',
  selectedDay: { value: DAYSELECT_OPTIONS.TODAY, label: 'Today' },
  startTime: { value: DEFAULT_START_TIME, label: DEFAULT_START_TIME },
  endTime: { value: DEFAULT_END_TIME, label: DEFAULT_END_TIME },
  selectedDateRange: [YESTERDAY, TODAY],
  previousXDays: undefined,
  changedQuantityGreaterThanX: undefined,
};

const StockChangeLogView = ({ hubSlug }: { hubSlug: string }) => {
  const [loaderRef, loadMore] = useInfiniteScroll();
  const [shouldResetList, setShouldResetList] = useState<boolean>(false);
  const { t } = useTranslation();
  const [filter, setFilter] = useState<filterType>(DEFAULT_FILTER);
  const [logsList, setLogsList] = useState<HubStockChangeLogs[]>([]);

  const showPreviousXDaysInput = filter.selectedDay.value === DAYSELECT_OPTIONS.PREVIOUS_X_DAYS;
  const showDateRangePicker = filter.selectedDay.value === DAYSELECT_OPTIONS.CUSTOM;

  const variables = useMemo(() => {
    const selectedDay =
      filter.selectedDay.value === DAYSELECT_OPTIONS.YESTERDAY ? YESTERDAY : TODAY;
    const startDate =
      showDateRangePicker || showPreviousXDaysInput ? filter.selectedDateRange[0] : selectedDay;
    const endDate =
      showDateRangePicker || showPreviousXDaysInput ? filter.selectedDateRange[1] : selectedDay;
    return {
      hubSlug,
      queryString: filter.queryString,
      sku: filter.sku,
      reasons: filter.selectedReasons as V1InventoryChangeReason[],
      from: buildDate({ selectedDate: startDate, time: filter.startTime.value }) || '',
      to: buildDate({ selectedDate: endDate, time: filter.endTime.value }) || '',
      invDiffGt: filter.changedQuantityGreaterThanX,
      offset: filter.offset,
    };
  }, [
    filter.changedQuantityGreaterThanX,
    filter.endTime.value,
    filter.offset,
    filter.queryString,
    filter.selectedDateRange,
    filter.selectedDay.value,
    filter.selectedReasons,
    filter.sku,
    filter.startTime.value,
    hubSlug,
    showDateRangePicker,
    showPreviousXDaysInput,
  ]);

  const { loading } = useGetHubStockChangeLogsQuery({
    variables: {
      input: variables,
    },
    onCompleted: (data) => {
      const logs = data?.getHubStockChangeLogs?.logs || [];
      setLogsList((prev) => (shouldResetList ? logs : [...prev, ...logs]));
      setShouldResetList(logs.length < PAGE_SIZE);
    },
  });

  useEffect(() => {
    if (loadMore && !shouldResetList)
      setFilter((pre) => ({ ...pre, offset: logsList.length.toString() }));
  }, [loadMore, shouldResetList, logsList.length]);

  const handleSelect = (option: Partial<filterType>) => {
    setShouldResetList(true);
    setFilter((pre) => {
      return { ...pre, ...option, offset: DEFAULT_FILTER.offset };
    });
  };

  const handlePreviousXDaysChange: NumberInputProps['onChange'] = useCallback(
    (previousXDays: number | undefined) => {
      let selectedDateRange = DEFAULT_FILTER.selectedDateRange;

      if (previousXDays) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - previousXDays);
        selectedDateRange = [startDate, TODAY];
      }

      handleSelect({
        previousXDays,
        selectedDateRange,
        startTime: DEFAULT_FILTER.startTime,
        endTime: DEFAULT_FILTER.endTime,
      });
    },
    []
  );

  const handleChangedQuantityFilter: NumberInputProps['onChange'] = useCallback(
    (value: number | undefined) => handleSelect({ changedQuantityGreaterThanX: value }),
    []
  );

  const memoizedQueryOnChange = useCallback(
    (searchQuery: { queryString?: string; sku?: string }) => handleSelect(searchQuery),
    []
  );

  const memoizeReasonOnChange = useCallback(
    (reason: string[]) => handleSelect({ selectedReasons: reason }),
    []
  );

  return (
    <div>
      <div className="flex flex-col gap-2 mb-8">
        <Searchbar
          placeholder={t('filter_log_search_product_sku') || undefined}
          name="product-search"
          onChange={memoizedQueryOnChange}
        />

        <div className="flex gap-x-8">
          <div className="flex gap-x-2">
            <div>
              <h4>{t('filter_by')}</h4>
              <ReasonSelector onChange={memoizeReasonOnChange} variant="large" />
            </div>

            <div>
              <h4>{t('filter_log_changed_quantity')}</h4>
              <NumberInput
                placeholder={t('filter_log_changed_quantity_placeholder') ?? ''}
                name="inv_diff_gt"
                onChange={handleChangedQuantityFilter}
                inputClassnames="w-52"
              />
            </div>
          </div>

          <div>
            <h4 className="invisible">Selected Day(s)</h4>
            <Select
              options={Object.values(DAYSELECT_OPTIONS).map((day) => {
                return { value: day, label: t(`filter_log_${day}`) };
              })}
              displayValue={filter.selectedDay.label}
              onSelect={(option) =>
                handleSelect({
                  selectedDay: option,
                  selectedDateRange: DEFAULT_FILTER.selectedDateRange,
                })
              }
              selectedOption={filter.selectedDay}
              variant="medium"
              testId="select-day"
              name="select-day-input"
            />
          </div>

          {showPreviousXDaysInput && (
            <div>
              <h4>{t('filter_log_previous-x-days')}</h4>
              <NumberInput
                name="previous-x-days"
                placeholder={t('filter_log_previous-x-days') ?? ''}
                onChange={handlePreviousXDaysChange}
                defaultValue={2}
                min={2}
                max={30}
                inputClassnames="w-64"
              />
            </div>
          )}

          {showDateRangePicker && (
            <div>
              <h4>{t('filter_log_custom')}</h4>
              <DateRangePicker
                className="react-daterange-picker"
                calendarClassName="react-calendar"
                onChange={(option) => {
                  if (option) {
                    handleSelect({
                      selectedDateRange: option,
                      startTime: DEFAULT_FILTER.startTime,
                      endTime: DEFAULT_FILTER.endTime,
                    });
                  } else {
                    handleSelect(DEFAULT_FILTER);
                  }
                }}
                value={filter.selectedDateRange}
                format="dd.MM.y"
              />
            </div>
          )}

          {!showPreviousXDaysInput && !showDateRangePicker && (
            <div>
              <h4>{t('time_frame')}</h4>
              <div className="flex gap-x-2">
                <Select
                  options={getTimeOptions(FROM)}
                  displayValue={filter.startTime.label}
                  onSelect={(option) => {
                    if (option.value >= filter.endTime.value) {
                      handleSelect({ startTime: option, endTime: DEFAULT_FILTER.endTime });
                    } else {
                      handleSelect({ startTime: option });
                    }
                  }}
                  selectedOption={filter.startTime}
                  variant="small"
                />
                <Select
                  options={getTimeOptions(TO, filter.startTime.value)}
                  displayValue={filter.endTime.label}
                  onSelect={(option) => handleSelect({ endTime: option })}
                  selectedOption={filter.endTime}
                  variant="small"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-[180px_60px_1fr_250px_120px_120px_190px] gap-x-4 my-2">
        {headers.map((header) => (
          <p
            key={header}
            data-testid={header}
            className="font-medium text-center bg-flinkGray rounded py-2 px-1.5 w-fit"
          >
            {t(`logs_table_${header}`)}
          </p>
        ))}
      </div>
      {logsList.map((log, i) => (
        <StockChangeLog key={`${log.sku}${log.orderId}${log.createdAt}${i}`} log={log} />
      ))}
      <div className="spinner mx-4 my-4 h-10" ref={loaderRef}>
        {loading && <Spinner />}
      </div>
    </div>
  );
};

export default memo(StockChangeLogView);
